html, body, #root { min-height: 100%; height: auto; }

body {
  margin: 0;
  font-family: 'UHCSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, input, select, textarea, button { font-family: inherit; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.mapboxgl-popup.gymSearchMapMarkerPopup {
  border: 1px solid black;
  border-radius: 5px;
  top: -10px;
  left: 2px;
}

.mapboxgl-popup.gymSearchMapMarkerPopup .mapboxgl-popup-content {
  background: white;
  font-size: 15px;
  font-weight: 400;
  width: 150px;
  opacity: 1;
  padding: 10px;
}

.mapboxgl-popup.gymSearchMapMarkerPopup .mapboxgl-popup-tip {
  background: white;
  border-bottom-color: black;
  border-right-color: black;
  border-bottom-style: solid;
  border-right-style: solid;
  border-width: 1px !important; /* overriding border width 5px inline style */
  bottom: -9px;
  height: 15px;
  margin: 0 auto;
  position: absolute;
  transform: rotate(45deg);
  width: 15px;
}

.mapboxgl-popup-close-button {
  background: transparent;
  border: none;
  float: right;
  font-size: 20px;
  padding: 0 6px;
}

/* IE11 */
_:-ms-fullscreen, :root .mapboxgl-popup.gymSearchMapMarkerPopup {
  left: 4px;
}

_:-ms-fullscreen, :root .mapboxgl-popup.gymSearchMapMarkerPopup .mapboxgl-popup-tip { 
  left: 75px;
}

_:-ms-fullscreen, :root .resultText .foundText { 
  margin-top: -5px;
}
